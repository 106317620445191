/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Global, css } from '@emotion/core'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import {
  faBars,
  faLongArrowDown,
  faTimes,
} from '@fortawesome/pro-light-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import Header from './Header'

library.add(faBars, faTimes, faLongArrowDown)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Global
        styles={css`
          body {
            font-family: 'MaisonNeueMono';
          }
        `}
      />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      {/* <footer>© {new Date().getFullYear()}</footer> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
