import PropTypes from 'prop-types'
import React, { useState } from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { Link } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { breakpoints } from '../utils/breakpoints'

const Header = ({ siteTitle }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleToggle = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  const MenuButton = styled.button`
    ${tw`fixed top-0 p-8 lg:p-10 right-0 z-50 text-3xl md:text-4xl
    focus:outline-none`}

    ${menuIsOpen && tw`text-white`}
  `

  const MenuWrap = styled.div`
    ${tw`fixed flex items-center justify-center z-40 bg-black`}
    height: 100vh;
    width: 100vw;

    ${!menuIsOpen && tw`hidden`}
  `

  const Menu = styled.nav`
    ${tw``}

    ul {
      li {
        ${tw`py-3 text-white text-center font-display font-medium uppercase leading-none tracking-wide`}
        font-size: 8vw;
        @media (min-width: ${breakpoints.md}px) {
          font-size: 5vw;
        }

        a {
          ${tw`cursor-pointer`}
        }
      }
    }
  `

  return (
    <>
      <MenuButton onClick={() => handleToggle(menuIsOpen)}>
        {!menuIsOpen ? (
          <FontAwesomeIcon icon={['fal', 'bars']} />
        ) : (
          <FontAwesomeIcon icon={['fal', 'times']} />
        )}
      </MenuButton>
      <MenuWrap>
        <Menu>
          <ul>
            <li>
              <Link
                to="home"
                smooth
                duration={1000}
                onClick={() => handleToggle(menuIsOpen)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="namaste"
                smooth
                duration={1000}
                onClick={() => handleToggle(menuIsOpen)}
              >
                Namaste
              </Link>
            </li>
            <li>
              <Link
                to="registration"
                smooth
                duration={1000}
                onClick={() => handleToggle(menuIsOpen)}
              >
                Registration
              </Link>
            </li>
            <li>
              <Link
                to="chant"
                smooth
                duration={1000}
                onClick={() => handleToggle(menuIsOpen)}
              >
                Opening Chant
              </Link>
            </li>
          </ul>
        </Menu>
      </MenuWrap>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
